import React from 'react';
import './Header.css'

const Header = ({showFilterColumn, isFilterOpen, hideFilterColumn}) => {
    return (
        <div className="main-header">
            <div className='header-icon-div'>
                {isFilterOpen ? (
                    <div onClick={hideFilterColumn}>
                        <img src='./images/close-menu-icon.png' alt='hamburger icon' className='header-icon' />
                        <p className='white-txt'>Close Menu</p>
                    </div>
                ) : (
                    <div onClick={showFilterColumn}>
                        <img src='./images/menu-icon.png' alt='hamburger icon' className='header-icon' />
                        <p className='white-txt'>Filters Menu</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;