import React from 'react';
import './SmallCard.css';

 
const SmallCard = ({ indvCompany, handleSmallCardClick }) => {
    const { account, company, rez_prog, rez_serve, com_serve, hp_used, phone, zip } = indvCompany

    const rez_prog_arr = rez_prog ? rez_prog.split(/[,;]+/) : [];

    return (
        <div key={account} className="small-card">
            <div class='small-card-header'>
                <img src='./images/person-icon-dark.png' alt='card' className='card-person'/>
            </div>
            <div className='inner-card-holder'>
                <p>{company}</p>
                <div className='top-card-info'>
                    {phone && <div className='info-split'>
                        <img src='./images/phone-icon.png' alt='phone icon' />
                        <p>{phone}</p>
                    </div>}

                    {zip && <div className='info-split'>
                        <img src='./images/location-icon.png' alt='location icon' />
                        <p>{zip}</p>
                    </div>}
                </div>
                <hr />

                <p>Program Types</p>
                
                {rez_serve && (
                    rez_prog_arr.map((type) => (
                        <p>CCEF Residential Program - {type.trim()}</p>
                    ))
                )}

                {com_serve && (
                    <p>CCEF Commercial Program</p>
                )}

                {hp_used && (
                    <p>CEO Heat Pump Tax Credits Program</p>
                )}

                <button onClick={handleSmallCardClick} className='small-card-btn green-bg white-txt'>View Details</button>
            </div>
        </div>
    )
}
 
export default SmallCard