import React from 'react';
import './LanguageFilter.css'

const LanguageFilter = ({filterEnglish, setFilterEnglish, filterSpanish, setFilterSpanish, filterOther, setFilterOther}) => {
   return (
    <div className="languages-checkboxes">
        <p>Languages:</p>
        <label>
            <input
                type="checkbox"
                checked={filterEnglish}
                onChange={() => setFilterEnglish(!filterEnglish)}
            /> English
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterSpanish}
                onChange={() => setFilterSpanish(!filterSpanish)}
            /> Spanish
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterOther}
                onChange={() => setFilterOther(!filterOther)}
            /> Other
        </label>
    </div>
    );
};

export default LanguageFilter;