import React, { useState } from 'react';
import './NameSearch.css';

const NameSearch = ({ companyNames, handleCompanySelection, searchTerm, setSearchTerm }) => {
    const [showList, setShowList] = useState(false);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFocus = () => {
        setShowList(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setShowList(false);
        }, 100);
    };

    const handleCompanyClick = (company) => {
        setSearchTerm(company);
        handleCompanySelection(company);
        setShowList(false);
    };

    const handleClear = () => {
        setSearchTerm('');
        handleCompanySelection('')
    };

    const filteredCompanies = companyNames.filter(company =>
        company.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="name-search">
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Company Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="search-bar"
                />
                {searchTerm && <button className="clear-button" onClick={handleClear}>×</button>}
            </div>
            {showList && (
                <ul className="company-list">
                    {filteredCompanies.map((company, index) => (
                        <li key={index} onMouseDown={() => handleCompanyClick(company)}>
                            {company}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NameSearch;
