import React from 'react';
import './CountiesSearch.css'

const CountiesSearch = ({counties, handleCountyChange, selectedCounty}) => {
   return (
        <div className="counties-search">
            <p>Counties served</p>
            <select onChange={(e) => handleCountyChange(e.target.value)} value={selectedCounty}>
                <option value="">Select a County</option>
                {counties.map((county, index) => (
                    <option key={index} value={county}>{county}</option>
                ))}
            </select>
        </div>
    );
};

export default CountiesSearch;