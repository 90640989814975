import React from 'react';
import SingleCompany from '../SingleCompany/SingleCompany';
import './ResultColumn.css'

const ResultColumn = ({isSingleCompanyOpen, singleCompanyRef, selectedCompany, handleSingleCompanyClose, toTitleCase, showCardColumn}) => {
   return (
        <div className="result-column">
            {isSingleCompanyOpen ? (
                <div ref={singleCompanyRef}>
                    <SingleCompany indvCompany={selectedCompany}  onClose={handleSingleCompanyClose} toTitleCase={toTitleCase} showCardColumn={showCardColumn} />
                </div>
            ) : (
                <div className='no-results'>
                    <img src='./images/person-icon-light.png' alt='result card image'/>
                    <p className='grey-txt'>No Contractor Selected</p>
                </div>
            )} 
        </div>
    );
};

export default ResultColumn;