import React from 'react';
import './ServicesOffered.css'

const ServicesOffered = ({services, handleServiceChange, selectedService}) => {
   return (
        <div className="services-search">
            <p>Services offered</p>
            <select onChange={(e) => handleServiceChange(e.target.value)} value={selectedService}>
                <option value="">Select a Service</option>
                {services.map((service, index) => (
                    <option key={index} value={service}>{service}</option>
                ))}
            </select>
        </div>
    );
};

export default ServicesOffered;