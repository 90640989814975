import React from 'react';
import './SingleCompany.css'

const SingleCompany = ({ indvCompany, toTitleCase, showCardColumn }) => {
    const { account, company, email, phone, website, rez_prog, rez_serve, com_serve, hp_used, street, city, state, zip, languages, lang_other } = indvCompany;

    const processLanguages = (languages, otherLanguages) => {
        const delimiter = /[,\/&|;]+|\sand\s/;
        const langArray = [...(languages ? languages.split(delimiter) : []), ...(otherLanguages ? otherLanguages.split(delimiter) : [])];
        
        const filteredLanguages = langArray.map(lang => toTitleCase(lang.trim())).filter(lang => lang && lang !== 'Other');
        
        return [...new Set(filteredLanguages)];
    };

    const rez_prog_arr = rez_prog ? rez_prog.split(/[,;]+/) : [];
    const rez_serve_arr = rez_serve ? rez_serve.split(/[,;]+/) : [];
    const comm_serve_arr = com_serve ? com_serve.split(/[,;]+/) : [];
    const hp_used_arr = hp_used ? hp_used.split(/[,;]+/) : [];
    const formattedWebsite = website ? (website.startsWith('http://') || website.startsWith('https://') ? website : `http://${website}`) : '';
    const processedLanguages = processLanguages(languages, lang_other)
    const languagesDisplay = processedLanguages.join(', ')

    return (
        <div key={account}>
            <div className='results'>
                <img src='./images/person-icon-dark.png' alt='result card'/>
                <p>{company}</p>
            </div>
            <div className="single-company-card offwhite-bg">
                <div className='top-single-card white-bg'>
                    <p>Business Info</p>
                    <hr />
                    <div className='card-split-div'>
                        {zip && <div className='card-single-split'>
                            <img src='./images/location-icon.png' alt='location icon' />
                            <p>{street},<br />{city}, {state} {zip}</p>
                        </div>}

                        {phone && <div className='card-single-split'>
                            <img src='./images/phone-icon.png' alt='phone icon' />
                            <p>{phone}</p>
                        </div>}

                        <div className='card-single-split'>
                            <img src='./images/email-icon.png' alt='email icon' className='email-img' />
                            <p>{email}</p>
                        </div>

                        {website && <div className='card-single-split'>
                            <img src='./images/website-icon.png' alt='website icon' />
                            <a href={formattedWebsite} target='_blank' rel="noopener noreferrer" className='dark-txt'>
                                <p>{website}</p>
                            </a>
                        </div>}

                        {languagesDisplay && <div className='card-single-split'>
                            <img src='./images/language-icon.png' alt='language icon' />
                            <p>{languagesDisplay}</p>
                        </div>}
                    </div>
                </div>

                <div className='mid-single-card white-bg'>
                    <p>Program Types</p>
                    <hr />
                    
                    {rez_serve && (
                        rez_prog_arr.map((type) => (
                            <p>CCEF Residential Program - {type.trim()}</p>
                        ))
                    )}

                    {com_serve && (
                        <p>CCEF Commercial Program</p>
                    )}

                    {hp_used && (
                        <p>CEO Heat Pump Tax Credits Program</p>
                    )}
                </div>

                <div className='bot-single-card white-bg'>
                    <p>Services Offered</p>
                    <hr />
                    <div className='serve-flex-div'>
                        {rez_serve_arr.map((serv) => (
                            <p>{serv.trim()}</p>
                        ))}

                        {comm_serve_arr.map((serv) => (
                            <p>{serv.trim()}</p>
                        ))}

                        {hp_used_arr.map((serv) => (
                            <p>{serv.trim()}</p>
                        ))}
                    </div>
                </div>

                <div className='reset-btn-div'>
                    <button onClick={showCardColumn} className="apply-btn green-bg white-txt sing-comp-btn">Back To Results</button>
                </div>
                
            </div>
        </div>
    );
};

export default SingleCompany;