import React from 'react';
import './ProjectTypes.css'

const ProjectTypes = ({filterCommercial, setFilterCommercial, filterResidential, setFilterResidential, filterBoth, setFilterBoth}) => {
   return (
    <div className="project-checkboxes">
         <p>Project type</p>
        <label>
            <input
                type="checkbox"
                checked={filterCommercial}
                onChange={() => setFilterCommercial(!filterCommercial)}
            /> Commercial
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterResidential}
                onChange={() => setFilterResidential(!filterResidential)}
            /> Residential
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterBoth}
                onChange={() => setFilterBoth(!filterBoth)}
            /> Both
        </label>
    </div>
    );
};

export default ProjectTypes;