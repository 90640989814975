import React from 'react';
import SmallCard from '../SmallCard/SmallCard'
import './CardColumn.css'

const CardColumn = ({companies, handleSmallCardClick}) => {
   return (
        <div className="card-column offwhite-bg">
            {
                companies?.map((company)=>(
                    <div>
                        <SmallCard key={company.company} indvCompany={company} handleSmallCardClick={() => handleSmallCardClick(company)} />
                    </div>
                ))
            }
        </div>
    );
};

export default CardColumn;